import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    this.electricData = JSON.parse(this.data.get("electric-data"))
    this.solarData = JSON.parse(this.data.get("solar-data"))
    this.labels = JSON.parse(this.data.get("labels"))
    this.setupChart()
  }

  setupChart() {
    var randomScalingFactor = function() {
      return Math.round(Math.random() * 100);
    };

    var places = this.labels

    var show_x_axis = true
    var show_y_axis = true
    if (this.data.get('axis') == 'false') {
      show_x_axis = false
      show_y_axis = false
    }

    var xAxisPosition = 'bottom'
    if (this.data.get('x-axis-position') == 'top') {
      show_x_axis = true
      xAxisPosition = 'top'
    }
    

    var config = {
      plugins: [ChartDataLabels],
      type: 'horizontalBar',
      data: {
        datasets: [
          {
            datalabels: {
              display: false
            },
            data: this.electricData,
            label: 'Electric',
            backgroundColor: 'rgba(44, 123, 229, 0.5)',
            lineTension: 0.1
          },
          {
            datalabels: {
              backgroundColor: 'white',
              color: 'black',
              paddingLeft: 10
            },
            data: this.solarData,
            // backgroundColor: colors
            backgroundColor: 'rgba(246, 196, 67, 1)',
            lineTension: 0.1,
            label: 'Solar'
          }
        ],
        labels: places,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'top',
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: 'nearest',
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10, 
          caretPadding: 0,
          displayColors: false,
          backgroundColor: 'grey',
          titleFontColor: 'white', 
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        scales: {
          xAxes: [
            {
              display: false, 
              position: 'top', 
              gridLines: {
                display: false
              },
              scaleLabel: {
                align: 'center'
              },
              stacked: true
            }
          ],
          yAxes: [{display: false, stacked: true, gridLines: false, position: 'right'}]
        },
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              // return context.chart.data.labels[context.dataIndex];
              return places[context.dataIndex];
            },
						display: true,
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            color: 'white',
            font: {
              weight: 'bold'
            },
            // formatter: Math.round,
						align: 'end',
						anchor: 'end'
          }
        }
      }
    };

    var ctx = this.chartTarget.getContext('2d');
    var myDoughnut = new Chart(ctx, config);
  }
}
