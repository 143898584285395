import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    setTimeout(this.redirect.bind(this), 15000);
  }

  redirect() {
    window.location.href = this.nextPage();
  }

  nextPage() {
    return this.data.get('next-page')
  }

}
