import { Controller } from "stimulus"
import Chart from 'chart.js'

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    this.chartData = JSON.parse(this.data.get("data"))
    this.labels = JSON.parse(this.data.get("labels"))
    this.setupChart()
  }

  setupChart() {
    var show_x_axis = true
    var show_y_axis = true

    var xAxisPosition = 'bottom'

    var config = {
      type: 'bar',
      data: {
        datasets: [
          {
            data: this.chartData,
            backgroundColor: 'rgba(246, 196, 67, 1)',
            lineTension: 0.1
          }
        ],
        labels: this.labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: 'nearest',
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10, 
          caretPadding: 0,
          displayColors: false,
          backgroundColor: 'grey',
          titleFontColor: 'white', 
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        scales: {
          xAxes: [
            {
              display: true, 
              position: xAxisPosition, 
              gridLines: {
                display: false
              },
              scaleLabel: {
                align: 'end'
              }
            }
          ],
          yAxes: [{display: show_y_axis, stacked: false}]
        }
      }
    };

    var ctx = this.chartTarget.getContext('2d');
    var myDoughnut = new Chart(ctx, config);
  }
}
